import React from 'react'
import { Navigate } from 'react-router-dom'
import { ProtectedRoute } from '../components/protected-route/ProtectedRoute'

const ListOfEvents = React.lazy(() => import('../views/reports/ListOfEvents'))
const EventSummary = React.lazy(() => import('../views/reports/EventSummary'))
const EventsCommissionBreakagePerGuarantor = React.lazy(() =>
  import('../views/reports/SourceCommissionFromGuarantors'),
)
const EventsCommissionBreakagePerOperator = React.lazy(() =>
  import('../views/reports/SourceCommissionFromOperators'),
)
const EventCommissionPerUserSources = React.lazy(() =>
  import('../views/reports/UserWalletCommissionSources'),
)

const ListofRaces = React.lazy(() => import('../views/reports/ListOfRaces'))
const ListOfBetsPerRace = React.lazy(() => import('../views/reports/ListOfBetsPerRace'))
const RaceSummary = React.lazy(() => import('../views/reports/RaceSummary'))
const BhistoryDownline = React.lazy(() => import('../views/reports/UserHistory'))
const Overview = React.lazy(() => import('../views/dashboard/Overview'))
const BetControl = React.lazy(() => import('../views/arena/BetControl'))
const CreateGameroom = React.lazy(() => import('../views/arena/CreateGameRoom'))
const GameControl = React.lazy(() => import('../views/arena/GameControl'))
const RegisterGuarantor = React.lazy(() => import('../views/load/RegisterGuarantor'))
const SystemConfig = React.lazy(() => import('../views/arena/SystemConfig'))
const CommissionManagement = React.lazy(() => import('../views/commission/CommissionManagement'))
const CompanyLoadManagement = React.lazy(() =>
  import('../views/companyloadmanagement/LoadCompanyFunds'),
)
const Users = React.lazy(() => import('../views/users/Users'))
const AdminReports = React.lazy(() => import('../views/adminreports/Reports'))
const DownlineHistory = React.lazy(() => import('../views/reports/HistoryDownline'))
const ActivityLogs = React.lazy(() => import('../views/activitylogs/AcitivityLogs'))
const ShowAdminActivityLogs = React.lazy(() =>
  import('../views/activitylogs/ShowAdminActivityLogs'),
)
const ShowUserActivityLogs = React.lazy(() =>
  import('../views/activitylogs/ShowNonAdminActivityLogs'),
)
const Requests = React.lazy(() => import('../views/requests/Requests'))
const RequestApproval = React.lazy(() => import('../views/requests/Approval'))
const Login = React.lazy(() => import('../views/login/Login'))
const AuthLayout = React.lazy(() => import('../components/layout/AuthLayout'))
const MainLayout = React.lazy(() => import('../components/layout/MainLayout'))
const GreenScreen = React.lazy(() => import('../components/greenScreen/GreenScreen'))

const superAdminRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: '/overview', name: 'Overview', element: <Overview /> },
      { path: '/bet-control', name: 'Bet-Control', element: <BetControl /> },
      { path: '/list-of-events', name: 'List of Events', element: <ListOfEvents /> },
      { path: '/event-summary', name: 'List of Races', element: <EventSummary /> },
      { path: '/list-of-races', name: 'List of Races', element: <ListofRaces /> },
      { path: '/list-of-bets', name: 'List of Bets', element: <ListOfBetsPerRace /> },
      { path: '/race-summary', name: 'List of Races', element: <RaceSummary /> },
      { path: '/downline-history', name: 'Downline history', element: <DownlineHistory /> },
      {
        path: '/user-commission-sources',
        name: 'Comm Sources',
        element: <EventCommissionPerUserSources />,
      },
      { path: '/user-history', name: 'User History', element: <BhistoryDownline /> },
      { path: '/register-guarantor', name: 'Register Guarantor', element: <RegisterGuarantor /> },
      { path: '/manage-commission', name: 'Manage Commission', element: <CommissionManagement /> },
      { path: '/create-gameroom', name: 'Create Gameroom', element: <CreateGameroom /> },
      { path: '/game-control/:gameRoomId', name: 'Current Game', element: <GameControl /> },
      { path: '/users', name: 'Users List', element: <Users /> },
      { path: '/company-reports', name: 'Admin Reports', element: <AdminReports /> },
      { path: '/activity-logs', name: 'Activity Logs', element: <ActivityLogs /> },
      { path: '/show-admin-activity-logs', name: 'Show Logs', element: <ShowAdminActivityLogs /> },
      { path: '/show-user-activity-logs', name: 'Show Logs', element: <ShowUserActivityLogs /> },
      { path: '/config', name: 'System Config', element: <SystemConfig /> },
      {
        path: '/events-commission-breakage-per-guarantor',
        name: 'Events Commission Breakage Per Guarantor',
        element: <EventsCommissionBreakagePerGuarantor />,
      },
      {
        path: '/commission-summary-breakage-per-operator',
        name: 'Events Commission Breakage Per Operator',
        element: <EventsCommissionBreakagePerOperator />,
      },
      {
        path: '/company-load-management',
        name: 'Company Funds Load Management',
        element: <CompanyLoadManagement />,
      },
      {
        path: '/green-screen',
        name: 'Bets Info',
        element: <GreenScreen />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/overview" />,
  },
]

export default superAdminRoutes
