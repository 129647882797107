import React from 'react'
import { Navigate } from 'react-router-dom'
import { ProtectedRoute } from '../components/protected-route/ProtectedRoute'

const ListOfEvents = React.lazy(() => import('../views/reports/ListOfEvents'))
const EventSummary = React.lazy(() => import('../views/reports/EventSummary'))
const ListofRaces = React.lazy(() => import('../views/reports/ListOfRaces'))
const RaceSummary = React.lazy(() => import('../views/reports/RaceSummary'))
const BhistoryDownline = React.lazy(() => import('../views/reports/UserHistory'))
const Overview = React.lazy(() => import('../views/dashboard/Overview'))
const CommissionManagement = React.lazy(() => import('../views/commission/CommissionManagement'))
const CompanyLoadManagement = React.lazy(() =>
  import('../views/companyloadmanagement/LoadCompanyFunds'),
)
const Requests = React.lazy(() => import('../views/requests/Requests'))
const RequestApproval = React.lazy(() => import('../views/requests/Approval'))
const Login = React.lazy(() => import('../views/login/Login'))
const AuthLayout = React.lazy(() => import('../components/layout/AuthLayout'))
const MainLayout = React.lazy(() => import('../components/layout/MainLayout'))
const AdminReports = React.lazy(() => import('../views/adminreports/Reports'))

const loaderRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: '/overview', name: 'Overview', element: <Overview /> },
      { path: '/list-of-events', name: 'List of Events', element: <ListOfEvents /> },
      { path: '/event-summary', name: 'List of Races', element: <EventSummary /> },
      { path: '/list-of-races', name: 'List of Races', element: <ListofRaces /> },
      { path: '/race-summary', name: 'List of Races', element: <RaceSummary /> },
      { path: '/user-history', name: 'User History', element: <BhistoryDownline /> },
      { path: '/manage-commission', name: 'Manage Commission', element: <CommissionManagement /> },
      {
        path: '/company-load-management',
        name: 'Company Funds Load Management',
        element: <CompanyLoadManagement />,
      },
      { path: '/company-reports', name: 'Admin Reports', element: <AdminReports /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/overview" />,
  },
]

export default loaderRoutes
