import React from 'react'
import { Navigate } from 'react-router-dom'
import { SignalRPublicProvider } from 'src/context/signalR/SignalRPublicContext'
import { useAuth } from 'src/hooks/auth/useAuth'

export const ProtectedRoute = (props) => {
  const { children } = props
  const { token, setToken } = useAuth()
  if (!token?.accessToken) {
    // user is not authenticated
    setToken(null)
    return <Navigate to="/" />
  }
  return <SignalRPublicProvider>{children}</SignalRPublicProvider>
}
